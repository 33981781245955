.Footer {
    background-color: #1a2833;
}

.Wrap {
    padding: 10px 0px;
    display: fixed;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1100px;
    margin: 0 auto;
}

.LinksContainer {
    display: flex;
    justify-content: center;

    @media screen and (max-width: 820px) {
        padding-top: 32px;
    }
}

.LinksWrapper {
    display: flex;

    @media screen and (max-width: 820px) {
        flex-direction: column;
    }
}

.LinkItems {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
    color: #fff;

    @media screen and (max-width: 420px) {
        margin: 0;
        padding: 10px;
        width: 100%;
    }
}

.LinkTitle {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 16px;
}

.Link {
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
    font-size: 14px;

    &:hover {
        color: #ff5e71;
        transition: 0.3s ease-out;
    }
}

.SocialMedia {
    max-width: 1000px;
    width: 100%;
}

.SocialMediaWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 650px;
    margin: 10px auto 0 auto;

    @media screen and (max-width: 820px) {
        flex-direction: column;
    }
}

.SocialLogo {
    color: #fff;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    width: 150px;
    font-weight: bold;
}

.WebsiteRights {
    color: #fff;
    margin-bottom: 16px;
}

.SocialIcons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 60px;
    margin-bottom: 17px;
}

.SocialIconLink {
    color: #ffffff;
    font-size: 24px;

    &:hover {
        color: #ff5e71;
        transition: 0.3s ease-out;
    }
}

.Img {
    width: 100%;
    margin: 0 0 10px 0;
    padding-right: 0;
}

.vl {
    margin: 0 10px;
    width: 2px;
    border: none;
    background-image: linear-gradient(#1a2833, #ff5e71, #1a2833);
}
