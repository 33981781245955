.Broadcasting {
    left: 0;
    top: 0;
    z-index: 0;
    background: #1a2833;
}

.Container {
    background: #ffffff;
    max-width: 1200px;
    height: auto;
    width: 100%;
    z-index: 1;
    display: grid;
    margin: 0 auto;
    padding: 32px 32px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.9);

    @media screen and (max-width: 480px) {
        padding: 32px 32px;
    }
}

.Icon {
    margin-left: 0px;
    margin-top: 32px;
    text-decoration: none;
    color: #fff;
    font-weight: 700;
    font-size: 32px;

    @media screen and (max-width: 480px) {
        margin-left: 16px;
        margin-top: 8px;
    }
}

.H1 {
    font-size: 2.7rem;
    color: #1a2833;
    margin-bottom: 20px;

    @media screen and (max-width: 600px) {
        font-size: 2rem;
        text-align: center; // Center-align the text on small screens
    }
}

.H2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #1a2833;
    margin-right: 30px;
    font-weight: 700;
    text-align: center;
}

.H2Top {
    font-size: 1.8rem;
    margin-bottom: 10px;
    color: #1a2833;
    margin-right: 30px;
    margin-left: 30px;
    margin-top: 10px;
}
.H2Red {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: red;
    margin-right: 30px;
    font-weight: 700;
}

.SubHeading1 {
    margin-right: 15px;
    margin-left: 15px;
}

.SubHeading2 {
    margin-right: 60px;
    margin-left: 60px;
    @media screen and (max-width: 500px) {
        margin-right: 20px;
        margin-left: 20px;
    }
    @media screen and (max-width: 400px) {
        margin-right: 10px;
        margin-left: 10px;
    }
}
.H3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #1a2833;
}

.P {
    font-size: 20px;
    text-align: justify;
    color: #1a2833;
}

.PNoJustify {
    text-align: center;
    font-size: 20px;
    color: #1a2833;
}

.PBlueQuote {
    font-size: 20px;
    text-align: left;
    color: #2f5597;
    font-style: italic;
    margin-left: 20px;
}

.Red {
    color: #f83d50;
}

.li {
    font-size: 1.1rem;
    text-align: left;
    padding: 5px;
    margin-left: 15px;
    margin-right: 15px;
    color: #1a2833;
}

.P2 {
    font-size: 1rem;
    text-align: center;
    color: #ffffff;
    margin-bottom: 0px;
}

.BtnWrap {
    display: flex;
    justify-content: flex-start;
    justify-content: center;
    align-items: center;
}

.Button {
    border-radius: 50px;
    max-width: 220px;
    background: #fc505f;
    white-space: nowrap;
    padding: 12px 30px;
    color: #ffffff;
    text-decoration: none;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2 ease-in-out;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);

    &:hover {
        transition: all 0.2 ease-in-out;
        background: #1a2833;
        color: #ffffff;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
    }
}

.Img {
    width: 100%;
    margin: 0 0 10px 0;
    padding-right: 0;
    align-items: center;
    border-radius: 15px;

    @media screen and (max-width: 430px) {
        margin: 0 8px; // Add margin to the left and right
    }
}

.ImgWrap {
    max-width: 1025px;
    padding: 32px;

    @media screen and (max-width: 430px) {
        padding: 16px; // Adjust the padding or add margins as needed
    }
}

.ImgWrap2 {
    max-width: 1000px;
    padding: 32px;

    @media screen and (max-width: 430px) {
        padding: 16px; // Adjust the padding or add margins as needed
    }
}

.ImgWrap3 {
    max-width: 1075px;
    padding: 32px;

    @media screen and (max-width: 430px) {
        padding: 16px; // Adjust the padding or add margins as needed
    }
}

.Link {
    color: #3a3af7;
    text-decoration: underline !important;
}

.SignUpDiv {
    display: flex;
    justify-content: center;
    align-items: center;
}

.SignUpButton {
    border-radius: 50px;
    background: #ff5e71;
    white-space: nowrap;
    padding: 7px 20px;
    color: #fff;
    font-size: 15px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #9ee5c4;
        color: black;
    }
}

.Title {
    margin-left: 20px;
    font-size: 1.5rem;
    font-weight: 700;
    color: #1a2833;
    margin-bottom: 10px;
}

.Side {
    width: 50%;
    float: left;
    margin-bottom: 40px;
}

.BulletList {
    list-style-type: disc;
    padding-left: 20px;
}

@media screen and (max-width: 768px) {
    .Side {
        width: 100%;
        float: none;
    }
}

.SignUpButton {
    border-radius: 50px;
    background: #ff5e71;
    white-space: nowrap;
    padding: 7px 20px;
    color: #fff;
    font-size: 15px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #9ee5c4;
        color: black;
    }
}

.ButtonDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.ButtonDiv2 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.FurtherReading {
    font-size: 18px;
    text-align: justify;
    color: #1a2833;
}
