.FindUs {
  left: 0;
  top: 0;
  z-index: 0;
  background: #1A2833;
}

.Wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 400px) {
      padding: 10px;
  }
}

.Icon {
  margin-left: 0px;
  margin-top: 32px;
  text-decoration: none;
  color: #fff;
  font-weight: 700;
  font-size: 32px;

  @media screen and (max-width: 480px) {
      margin-left: 16px;
      margin-top: 8px;
  }
}

.Content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 480px) {
      padding: 10px;
  }
}

.Form {
  background: #fc505f;
  max-width: 400px;
  height: auto;
  width: 100%;
  z-index: 1;
  display: grid;
  margin: 0 auto;
  padding: 80px 32px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.9);

  @media screen and (max-width: 400px) {
      padding: 32px 32px;
  }
}

.H1 {
  margin-bottom: 40px;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}

.Label {
  margin-bottom: 8px;
  font-size: 18px;
  color: #fff;
}

.Input {
  padding: 16px 16px;
  margin-bottom: 32px;
  border: none;
  border-radius: 4px;
}

.Button {
  background: #536f85;
  padding: 16px 0;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}

.Text {
  text-align: center;
  margin-top: 24px;
  color: #fff;
  font-size: 14px;
}

.Img {
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
}

.MarkerLocation {
    position: absolute;
    transform: translate(-50%, -100%);
    opacity: 0.9;
}

.Link {
  color: #ffffff;
  text-decoration: underline;
  margin-bottom: 0.5rem;
  font-size: 18x;

  @media screen and (max-width: 768px) {
    font-size: 18px
  }

  @media screen and (max-width: 480px) {
    font-size: 12px;
  }

  &:hover {
      color: #ff5e71;
      transition: 0.3s ease-out;
      cursor: pointer;
  }
}