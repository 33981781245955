.ContainerDark {
    color: #fff;
    background: #375368;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 65vh;
    min-height: 500px;

    @media screen and (max-width: 1000px) {
        height: 1500px;
    }
}

.Wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    max-width: 1100px;
    margin: 0 auto;
}

.TextWrapper {
    max-width: 540px;
    flex: 1;

    @media screen and (max-width: 768px) {
        width: 100%;
        order: 2;
        text-align: center;
        padding: 0 20px;
        margin-top: 20px;
    }
}

.Image {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    margin-right: 60px;
    margin-top: 40px;
    @media screen and (max-width: 1000px) {
        margin-right: 0;
    }
}

.Logo {
    text-align: center;
    padding-right: 20px;
}

.CoodashLogo {
    margin-top: 5px;
    width: 35px;
    height: 35px;
}

.CoodashText {
    width: 100px;
    height: 100px;
}

.TopLine {
    text-align: left;
    color: #fff;
    font-size: 60px !important;
    margin-bottom: -40px;
    letter-spacing: 1.4px;
    font-size: 41px;
}

.HeadingLight {
    text-align: left;
    margin-bottom: 20px;
    font-size: 40px;

    color: #f7f8f8;
}

.HeadingDark {
    text-align: left;
    margin-bottom: 20px;
    font-size: 40px;

    color: #f7f8f8;
}

.HeadingLight2 {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 15px;
    font-size: 30px;

    color: #f7f8f8;
    word-spacing: 5px;

    @media screen and (max-width: 500px) {
        text-align: center;
    }
}

.HeadingDark2 {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 15px;
    font-size: 30px;

    color: #f7f8f8;
    word-spacing: 5px;

    @media screen and (max-width: 500px) {
        text-align: center;
    }
}

.ImgWrap {
    height: 100%;
    margin-bottom: 10px;
}

.Img {
    border: 2px solid #c4c2c2;
    width: 100%;
    max-width: 400px;
    display: block;
    margin: 0 auto;
    border-radius: 15px;
    padding-right: 0;
}

.VidWrap {
    text-align: center;
    max-width: 555px;
    height: 100%;
}

.Vid {
    border-radius: 15px;
    width: 100%;
}

.Button {
    border-radius: 50px;
    background: #263947;
    white-space: nowrap;
    padding: 12px 30px;
    color: #ffffff;
    text-decoration: none;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2 ease-in-out;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);

    &:hover {
        transition: all 0.3 ease-in-out;
        background: #ff5e71;
        color: #ffffff;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
    }
}

.LinkTitle {
    color: #ff5e71;
    font-weight: bold;
    display: flex;
    justify-content: left;
    margin-left: 5px;
    @media screen and (max-width: 1000px) {
        text-align: center;
        margin-top: 100px;
        justify-content: center;
    }
    @media screen and (max-width: 700px) {
        margin-top: 150px;
    }
    @media screen and (max-width: 500px) {
        margin-top: 200px;
    }
    @media screen and (max-width: 400px) {
        margin-top: 250px;
    }
    @media screen and (max-width: 380px) {
        margin-top: 350px;
    }
    @media screen and (max-width: 360px) {
        margin-top: 375px;
    }
}

.Link {
    text-align: left;
    color: #ff5e71;
    text-decoration: none;
    font-size: 17px;

    &:hover {
        color: #9de5d6;
        transition: all 0.3s ease-in-out;
    }
}

.LinkFlip {
    text-align: right;
    color: #ff5e71;
    text-decoration: none;
    font-size: 17px;

    &:hover {
        color: #9de5d6;
        transition: all 0.3s ease-in-out;
    }
}

.SignUpDiv {
    margin-top: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.SignUpButton {
    border-radius: 50px;
    background: #ff5e71;
    white-space: nowrap;
    padding: 7px 20px;
    color: #fff;
    font-size: 15px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #9ee5c4;
        color: black;
    }
}

.Features {
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    display: grid;
    @media screen and (max-width: 1000px) {
        text-align: center;
        grid-auto-rows: 300px;
    }
    @media screen and (max-width: 380px) {
        grid-auto-rows: 250px;
    }
}

.Feature {
    text-align: left;
    @media screen and (max-width: 1000px) {
        padding-left: 0px;
        margin-left: 22%;
    }
    @media screen and (max-width: 480px) {
        margin-left: 18%;
    }
    @media screen and (max-width: 400px) {
        margin-left: 10%;
    }
    @media screen and (max-width: 350px) {
        margin-left: 7%;
    }
}

.FeatureIcon {
    @media screen and (max-width: 1000px) {
        display: block;
        margin-left: 9%;
    }
}

.FeatureTitle {
    font-size: 24px !important;
    @media screen and (max-width: 1000px) {
        text-align: left;
        margin-left: 10%;
    }
}

.Caption {
    font-size: 22px;
    text-align: justify;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 4px;
    max-width: 500px;
}

.Description {
    max-width: 600px;
    margin: 0 auto;
}

.LineDivider {
    width: 290px;
}

.Tick {
    color: #9ee5c4;
}

.Spacing {
    margin-bottom: -8px;
}

@media screen and (max-width: 1000px) {
    .Wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 100%;
        padding: 20px;
    }

    .Caption {
        text-align: center;
    }

    .Feature {
        text-align: center;
    }

    .TextWrapper {
        max-width: 100%;
        text-align: center;
        display: inline;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .Image {
        padding-top: 0;
    }

    .SignUpDiv {
        margin-top: 21px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .TopLine {
        text-align: center;
    }

    .HeadingLight,
    .HeadingDark,
    .HeadingLight2,
    .HeadingDark2 {
        text-align: center;
        margin-bottom: 20px;
    }

    .Features {
        text-align: center;
    }

    .Feature {
        margin-left: auto;
        margin-right: auto;
    }

    .Description {
        max-width: 100%;
        margin: 0 auto;
        text-align: center;
    }

    .LineDivider {
        width: 200px;
        margin: 0 auto;
    }

    .CoodashLogo {
        margin: 0 auto;
    }
}

.NoWrap {
    white-space: nowrap;
}
