.AboutUs {
    left: 0;
    top: 0;
    z-index: 0;
    background: #1a2833;
}

.Container {
    background: #ffffff;
    height: auto;
    width: 100%;
    z-index: 1;
    display: grid;
    margin: 0 auto;
    padding: 32px 32px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.9);

    @media screen and (max-width: 400px) {
        padding: 32px 32px;
    }
}

.Icon {
    margin-left: 0px;
    margin-top: 32px;
    text-decoration: none;
    color: #fff;
    font-weight: 700;
    font-size: 32px;

    @media screen and (max-width: 480px) {
        margin-left: 16px;
        margin-top: 8px;
    }
}

.H1 {
    font-size: 2.5rem;
    color: #1a2833;
    margin-bottom: 20px;

    @media screen and (max-width: 480px) {
        font-size: 2rem;
    }
}

.H2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #1a2833;
    text-align: center;
}

.P {
    font-size: 1rem;
    text-align: center;
    color: #1a2833;
}

.P2 {
    font-size: 1rem;
    text-align: center;
    color: #1a2833;
    margin-bottom: 0px;
}

.Form {
    background: #fc505f;
    max-width: 400px;
    height: auto;
    width: 100%;
    z-index: 1;
    display: grid;
    margin: 0 auto;
    padding: 80px 32px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.9);

    @media screen and (max-width: 400px) {
        padding: 32px 32px;
    }
}

.Label {
    margin-bottom: 8px;
    font-size: 14px;
    color: #fff;
}

.Input {
    padding: 16px 16px;
    margin-bottom: 32px;
    border: none;
    border-radius: 4px;
}

.BtnWrap {
    display: flex;
    justify-content: flex-start;
    justify-content: center;
    align-items: center;
}

.Button {
    border-radius: 50px;
    max-width: 200px;
    background: #fc505f;
    white-space: nowrap;
    padding: 12px 30px;
    color: #ffffff;
    text-decoration: none;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2 ease-in-out;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);

    &:hover {
        transition: all 0.2 ease-in-out;
        background: #1a2833;
        color: #ffffff;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
    }
}

.Text {
    text-align: center;
    margin-top: 24px;
    color: #fff;
    font-size: 14px;
}

.Img {
    width: 100%;
    margin: 0 0 10px 0;
    padding-right: 0;
    align-items: center;
}

.ImgWrap {
    max-width: 555px;
    height: 100%;
}

.Link {
    color: #1a2833;
    text-decoration: underline;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    justify-content: center;

    @media screen and (max-width: 768px) {
        font-size: 1rem;
    }

    @media screen and (max-width: 480px) {
        font-size: 1rem;
    }

    &:hover {
        color: #ff5e71;
        transition: 0.3s ease-out;
        cursor: pointer;
    }
}
