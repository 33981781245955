.ContainerLight {
    color: #fff;
    background-image: url("../../images/ScreenCapStatic.png");
    background-size: 100% auto;

    padding-top: 170px;
    height: 2900px;

    @media screen and (max-width: 1000px) {
        height: 1500px;
    }
    @media screen and (max-width: 768px) {
        padding: 100px 0;
    }
    @media screen and (max-width: 720px) {
        height: 1800px;
    }
}

.ContainerInsights {
    color: #fff;
    background: #273c4c;
    padding-top: 15px;
    height: 1200px;
}

.Wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
}

.TextWrapper {
    padding-top: 50px;
    text-align: center;

    @media screen and (max-width: 768px) {
        width: 100%;
        order: 2;
        padding: 0 20px;
        margin-top: 20px;
    }
}

.TextWrapperTech {
    padding: 0 20px;
    padding-top: 50px;
    text-align: center;
    max-width: 830px;
    margin-right: 100px;

    @media screen and (max-width: 768px) {
        width: 100%;
        order: 2;
        padding: 0 20px;
        margin-top: 20px;
    }

    @media screen and (max-width: 1000px) {
        margin-right: 0px;
    }
}

.Image {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
}

.Logo {
    text-align: center;
    padding-right: 20px;
}

.CoodashLogo {
    margin-top: 5px;
    width: 35px;
    height: 35px;
}

.CoodashText {
    width: 100px;
    height: 100px;
}

.TopLine {
    text-align: left;
    color: #fff;
    font-size: 55px !important;
    line-height: 16px;
    letter-spacing: 1.4px;
    margin-bottom: 25px;
    font-size: 41px;
}

.HeadingDark {
    text-align: left;
    margin-bottom: 20px;
    font-size: 55px;
    line-height: 1.1;
    color: #f7f8f8;
}

.SubHeadingDark {
    text-align: left;
    margin-bottom: 20px;
    font-size: 30px;
    line-height: 1.1;
    color: #f7f8f8;
    max-width: 1000px;
}

.HeadingLight2 {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 15px;
    font-size: 30px;
    line-height: 1.1;
    color: #f7f8f8;
    word-spacing: 5px;

    @media screen and (max-width: 500px) {
        text-align: center;
    }
}

.HeadingDark2 {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 15px;
    font-size: 30px;
    line-height: 1.1;
    color: #f7f8f8;
    word-spacing: 5px;

    @media screen and (max-width: 500px) {
        text-align: center;
    }
}

.ImgWrap {
    margin-top: 15px;
    height: 100%;
    margin-bottom: 10px;
}

.Img {
    border: 2px solid #c4c2c2;
    width: 100%;
    max-width: 400px;
    display: block;
    margin: 0 auto;
    border-radius: 15px;
    padding-right: 0;
}

.VidWrap {
    text-align: center;
    max-width: 555px;
    height: 100%;
}

.Vid {
    border-radius: 15px;
    width: 100%;
}

.Button {
    border-radius: 50px;
    background: #263947;
    white-space: nowrap;
    padding: 12px 30px;
    color: #ffffff;
    text-decoration: none;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2 ease-in-out;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);

    &:hover {
        transition: all 0.3 ease-in-out;
        background: #ff5e71;
        color: #ffffff;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
    }
}

.LinkTitle {
    color: #ff5e71;
    font-weight: bold;
    display: flex;
    justify-content: right;
    margin-bottom: 35px;
    margin-right: 452px;
}

.Link {
    text-align: left;
    color: #ff5e71;
    text-decoration: none;
    font-size: 17px;

    &:hover {
        color: #9de5d6;
        transition: all 0.3s ease-in-out;
    }
}

.LinkFlip {
    text-align: right;
    color: #ff5e71;
    text-decoration: none;
    font-size: 17px;

    &:hover {
        color: #9de5d6;
        transition: all 0.3s ease-in-out;
    }
}

.SignUpDiv {
    margin-top: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.SignUpButton {
    border-radius: 50px;
    background: #ff5e71;
    white-space: nowrap;
    padding: 7px 20px;
    color: #fff;
    font-size: 15px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #9ee5c4;
        color: black;
    }
}

.Features {
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    display: grid;
    @media screen and (max-width: 1000px) {
        text-align: center;
        grid-auto-rows: 300px;
    }
    @media screen and (max-width: 720px) {
        max-width: 500px;
        text-align: center;
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: 200px;
    }
    @media screen and (max-width: 380px) {
        grid-auto-rows: 250px;
    }
}

.Feature {
    text-align: left;
    @media screen and (max-width: 1000px) {
        padding-left: 0px;
        margin-left: 22%;
    }
    @media screen and (max-width: 480px) {
        margin-left: 18%;
    }
    @media screen and (max-width: 400px) {
        margin-left: 10%;
    }
    @media screen and (max-width: 350px) {
        margin-left: 7%;
    }
}

.FeatureIcon {
    @media screen and (max-width: 1000px) {
        display: block;
        margin-left: 9%;
    }
}

.FeatureTitle {
    font-size: 24px !important;
    @media screen and (max-width: 1000px) {
        text-align: left;
        margin-left: 10%;
    }
}

.Caption {
    font-size: 22px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 4px;
}

.Description {
    max-width: 600px;
    margin: 0 auto;
}

.LineDivider {
    width: 480px;
    // max-width: 512px; ?
    max-width: 100%;
    margin-top: -20px;
}

.LineDividerDiv {
    margin-top: 8px;
    margin-bottom: 8px;
}

.Tick {
    color: #9ee5c4;
}

.Spacing {
    margin-bottom: -8px;
}

.RedText {
    color: #fb3a4b;
    white-space: nowrap;
}

.Bullets {
    margin-top: -20px;
}

.WrapperAfter {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
}

.TextWrapperAfter {
    padding-top: 50px;
    margin-right: 0px;
    text-align: right;
    max-width: 850px;

    @media screen and (max-width: 768px) {
        width: 100%;
        order: 2;
        padding: 0 20px;
        margin-top: 20px;
    }
}

.HeadingDarkAfter {
    text-align: right;
    font-size: 55px;
    line-height: 1.1;
    color: #f7f8f8;
}

.SubHeadingDarkAfter {
    text-align: right;
    font-size: 30px;
    line-height: 1.1;
    color: #f7f8f8;
}

.FeaturesAfter {
    margin-left: auto;
    margin-right: auto;
    text-align: Right;
    display: grid;
    @media screen and (max-width: 1000px) {
        text-align: center;
        grid-auto-rows: 300px;
    }
    @media screen and (max-width: 720px) {
        max-width: 500px;
        text-align: center;
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: 200px;
    }
    @media screen and (max-width: 380px) {
        grid-auto-rows: 250px;
    }
}

.FeatureAfter {
    text-align: right;
    @media screen and (max-width: 1000px) {
        padding-left: 0px;
        margin-left: 22%;
    }
    @media screen and (max-width: 480px) {
        margin-left: 18%;
    }
    @media screen and (max-width: 400px) {
        margin-left: 10%;
    }
    @media screen and (max-width: 350px) {
        margin-left: 7%;
    }
}

.FirstBulletAfter {
    max-width: 1000px;
    margin-left: 60px;
    margin-top: -20px;
}

.BulletsAfter {
    margin-top: 20px;
    max-width: 1000px;
    margin-left: 60px;
}

.CoodashCool {
    color: #caf5e2;
    font-weight: 700;
}

.CoodashHot {
    color: #fb3a4b;
    font-weight: 700;
    white-space: nowrap;
}

.ThickDivider {
    width: 130px;
}

@media screen and (max-width: 1000px) {
    .ContainerLight,
    .ContainerDark {
        height: auto;
        padding: 100px 0;
    }

    .Wrapper,
    .WrapperAfter {
        text-align: center;
    }

    .TextWrapper,
    .TextWrapperAfter {
        padding: 0 20px;
        width: 100%;
    }

    .Features,
    .FeaturesAfter {
        text-align: center;
        grid-auto-rows: auto;
    }

    .Feature,
    .FeatureAfter {
        margin-left: 0;
        padding-left: 0;
    }

    .HeadingDark,
    .HeadingDarkAfter,
    .SubHeadingDark,
    .SubHeadingDarkAfter {
        text-align: center;
    }
    .Features,
    .FeaturesAfter {
        text-align: center;
        grid-auto-rows: auto;
    }

    .Feature,
    .FeatureAfter {
        margin-left: 0;
        padding-left: 0;
    }

    .Caption,
    .Description,
    .Bullets,
    .BulletsAfter,
    .FirstBulletAfter {
        text-align: center;
        margin-left: 0;
        margin-right: 0;
    }
    .LineDivider {
        margin: 0 auto;
    }
    .TextWrapperAfter {
        text-align: center;
    }

    .Bullets {
        margin-top: -5px;
    }
}

.SmallerText {
    font-size: 25px;
}

.LineBreak {
    margin-bottom: 10px;
}

.RealtimeData {
    text-align: left;
    margin-right: 50px;

    @media screen and (max-width: 1000px) {
        text-align: center;
        margin-right: 0;
    }
}

.RealtimeData h1,
.RealtimeData h4,
.RealtimeData h2,
.RealtimeData .Features,
.RealtimeData .Caption,
.RealtimeData .Bullets,
.RealtimeData .LineDividerDiv {
    text-align: left !important;

    @media screen and (max-width: 1000px) {
        text-align: center !important;
    }
}

.NoWrap {
    white-space: nowrap;
}

.LinkTitle {
    text-align: right;
    align-items: right;

    color: #ff5e71;
    font-weight: bold;
    display: flex;
    justify-content: right;
    margin-bottom: 35px;
    margin-right: 0px;
    @media screen and (max-width: 1000px) {
        text-align: center;
        align-items: center;
        margin-top: 25px;
        margin-right: 45%;
    }
    @media screen and (max-width: 700px) {
        margin-top: 50px;
        margin-right: 40%;
    }
    @media screen and (max-width: 400px) {
        margin-right: 35%;
    }
}

.Link2 {
    text-align: left;
    color: #ff5e71;
    text-decoration: none;
    font-size: 18px;

    &:hover {
        color: #9de5d6;
        transition: all 0.3s ease-in-out;
    }
    @media screen and (max-width: 1000px) {
        text-align: center;
        align-items: center;
    }
}
