.Navbar {
    background: #1a2833;
    height: 60px;
    margin-top: -60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 10;

    @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
    }
}

.Transparent {
    background: transparent;
}

.Container {
    display: flex;
    justify-content: space-between;
    height: 60px;
    z-index: 1;
    width: 100%;
    padding: 35px 24px;
    max-width: 1100px;
}

.Logo {
    color: #fff;
    justify-self: flex-start;
    cursor: pointer;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-left: 0px;
    margin-top: 2px;
    font-weight: bold;
    text-decoration: none;
}

.MobileIcon {
    display: none;

    @media screen and (max-width: 1000px) {
        align-self: center;
        display: flex;
        color: #fff;
        margin-left: 10px;
        align-self: center;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 25px;
        cursor: pointer;
        color: white;
    
        &:hover {
            color: #fc505f;
            transition: 0.3s ease-out;
            font-size: 28px;
        }
    }
}

.MobileIconHidden {
    display: none;
}

.Menu {
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;

    @media screen and (max-width: 1000px) {
        display: none;
    }
}

.Item {
    height: 60px;
    text-decoration: none;
    align-content: center;
}

.Links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;

    &:hover {
        color: #fc505f;
    }
}

.LinksActive {
    border-bottom: 3px solid #fc505f;
}

.Btn {
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px) {
        display: none;
    }
}



.NewsButton {
    border-radius: 50px;
    background: #ff5e71;
    white-space: nowrap;
    padding: 7px 20px;
    margin: 5px 5px;
    color: #fff;
    font-size: 15px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #9ee5c4;
        color: #000;
    }

    @media screen and (max-width: 1200px) {
        margin-right: 30px;
    }
}
.FinButton {
    text-align: center;
    border-radius: 50px;
    background: #ff5e71;
    white-space: nowrap;
    padding: 7px 20px;
    width: 145px;
    color: #fff;
    font-size: 15px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #9ee5c4;
        color: #000;
    }
}

.Img {
    min-width: 150px;
    margin: 0 0 10px 0;
    padding-right: 0;
}

.BtnWrap {
    justify-content: right;
    flex-grow: 1;
}

.BurgerButton {
    align-self: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 25px;
    cursor: pointer;
    color: white;

    &:hover {
        color: #fc505f;
        transition: 0.3s ease-out;
        font-size: 28px;
    }
}

.Link {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    color: #fff;
    cursor: pointer;

    &:hover {
        color: #f83d50;
        transition: 0.2s ease-in-out;
    }
}

.Modal {
    background-color: #1a2833;
    width: 100%;
}

.ModalHeader {
    background-color: #1a2833;
    color: white;
    justify-content: right;
}

.BtnWrap {
    align-self: center;
    display: flex;
    flex-grow: 1;

    @media screen and (max-width: 410px) {
        display: none;
    }

}

.Button {
    text-align: center;
    border-radius: 50px;
    background: #ff5e71;
    white-space: nowrap;
    padding: 7px 20px;
    width: 140px;
    margin: 5px 5px;
    margin-left: 3px;
    color: #fff;
    font-size: 15px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #9ee5c4;
        color: #000;
    }
}

.Close {
    color: #fff;
    cursor: pointer;
    font-size: 25px;
    justify-content: right;

    &:hover {
        color: #f83d50;
        transition: 0.2s ease-in-out;
        font-size: 28px;
    }
}

.CloseRow {
    padding-left: 97%;
}