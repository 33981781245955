.Products {
    height: 60vh;
    min-height: 3cqmin;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #172733;

    @media screen and (max-width: 768px) {
        height: 850px;
    }

    @media screen and (max-width: 480px) {
        height: 1000px;
    }
}

.Wrapper {
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: 30px;
    padding: 0 50px;
    margin-bottom: 50px;

    @media screen and (max-width: 1000px) {
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        padding: 0 20px;
    }
}

.Card {
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    max-height: 300px;
    padding: 30px;
    width: 240px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease-in-out;

    &:hover {
        transform: scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        color: #ff5e71;
        transition: 0.3s ease-out;
    }
}

.Icon {
    height: 160px;
    margin-bottom: 10px;
    margin-top: -10px;
    border-radius: 10px;
}

.DataInsightsIcon {
    height: 160px;
    margin-bottom: 10px;
    margin-top: -10px;
}

.NewsHubIcon {
    height: 160px;
    margin-bottom: 10px;
    margin-top: -10px;
    border-radius: 10px;
}

.H1 {
    font-size: 3rem;
    color: #fff;
    margin-bottom: 40px;

    @media screen and (max-width: 480px) {
        font-size: 2rem;
    }
}

.H2 {
    font-size: 1rem;
    margin-bottom: 10px;
    color: #1a2833;
    font-weight: bold;
}

.P {
    font-size: 15px;
    text-align: center;
    color: #1a2833;
}
