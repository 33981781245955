.cookie-banner {
    position: fixed;
    bottom: 40px;
    left: 10%;
    right: 10%;
    width: 80%;
    padding: 5px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #eee;
    border-radius: 5px;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.close {
    height: 20px;
    background-color: #777;
    border: none;
    color: white;
    border-radius: 2px;
    cursor: pointer;
}

.RedText {
    color: #f83d50;
    font-weight: 700;
}

.DivSpace {
    padding-bottom: 10px;
    padding-top: 10px;
}

.CoodashCool {
    color: #caf5e2;
    font-weight: 700;
}

.HeroSection {
    background: #010606;
    display: flex;
    padding: 0 30px;
    height: 85vh;
    min-height: 650px;
    position: relative;
    z-index: 1;
}

.Gradient {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.2) 0%,
            rgba(0, 0, 0, 0.6) 100%
        ),
        linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 70%);
    z-index: 2;
}

.Bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden !important;
}

.VideoBg {
    width: 100%;
    height: 100%;
    -o-object-fit: vcover;
    object-fit: cover;
    background: transparent;
}

.Content {
    margin-top: 200px;
    z-index: 3;
    max-width: fit-content;
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden !important;
}

.TextContainerLeft {
    margin-top: 150px;
    margin-left: 5vh;
    position: absolute;
    top: 20px;
    left: 20px;
    display: flex;
    flex-direction: column;
    text-align: left;

    @media screen and (max-width: 900px) {
        font-size: 30px;
        margin: auto;
        width: 70%;
        text-align: center;
        left: 0;
        right: 0;
        max-width: 9999px;
        margin-top: 70px;
    }
}

.TextContainerRight {
    margin-bottom: 100px;
    margin-right: 5vh;
    position: absolute;
    font-size: 30px;
    bottom: 15px;
    right: 20px;
    color: #fff;
    text-justify: inter-word;
    max-width: 40%;
    @media screen and (max-width: 1450px) {
        font-size: 20px;
    }

    @media screen and (max-width: 900px) {
        margin: auto;
        width: 70%;
        text-align: center;
        left: 0;
        right: 0;
        max-width: 9999px;
        margin-bottom: 30px;
        font-size: 20px;
    }

    @media screen and (max-width: 480px) {
        font-size: 17px;
    }
}

.H1 {
    font-size: 60px;

    @media screen and (max-width: 900px) {
        font-size: 40px;
    }
}

.H2 {
    font-size: 35px;

    @media screen and (max-width: 900px) {
        font-size: 30px;
    }

    .RedText {
        color: #f83d50;
        font-weight: 700;
        white-space: nowrap; /* Prevent line break */
    }
}

.NoWrap {
    white-space: nowrap;
}
