.AboutUs {
    left: 0;
    top: 0;
    z-index: 0;
    background: #1a2833;
}

.Container {
    background: #ffffff;
    height: auto;
    width: 100%;
    z-index: 1;
    display: grid;
    margin: 0 auto;
    padding: 32px 32px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.9);

    @media screen and (max-width: 400px) {
        padding: 32px 32px;
    }
}

.Icon {
    margin-left: 0px;
    margin-top: 32px;
    text-decoration: none;
    color: #fff;
    font-weight: 700;
    font-size: 32px;

    @media screen and (max-width: 480px) {
        margin-left: 16px;
        margin-top: 8px;
    }
}

.H1 {
    font-size: 2.5rem;
    color: #1a2833;
    margin-bottom: 20px;

    @media screen and (max-width: 480px) {
        font-size: 2rem;
    }
}

.H2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #1a2833;
    text-align: center;
}

.P {
    font-size: 1rem;
    text-align: justify;
    color: #1a2833;
}

.P2 {
    font-size: 1rem;
    text-align: center;
    color: #1a2833;
    margin-bottom: 0px;
}

.Form {
    background: #fc505f;
    max-width: 400px;
    height: auto;
    width: 100%;
    z-index: 1;
    display: grid;
    margin: 0 auto;
    padding: 80px 32px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.9);

    @media screen and (max-width: 400px) {
        padding: 32px 32px;
    }
}

.Label {
    margin-bottom: 8px;
    font-size: 14px;
    color: #fff;
}

.Input {
    padding: 16px 16px;
    margin-bottom: 32px;
    border: none;
    border-radius: 4px;
}

.BtnWrap {
    display: flex;
    justify-content: flex-start;
    justify-content: center;
    align-items: center;
}

.Button {
    border-radius: 50px;
    max-width: 200px;
    background: #ff5e71;
    white-space: nowrap;
    padding: 12px 30px;
    color: #ffffff;
    text-decoration: none;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2 ease-in-out;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);

    &:hover {
        transition: 0.3s ease-out;
        background: #9ee5c4;
        color: #000;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
    }
}

.Text {
    text-align: center;
    margin-top: 24px;
    color: #fff;
    font-size: 14px;
}

.Img {
    margin-top: 20px;
    width: 200px;

    @media (max-width: 650px) {
        width: 150px;
    }
}

.Link {
    color: #1a2833;
    text-decoration: underline;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    justify-content: center;

    @media screen and (max-width: 768px) {
        font-size: 1rem;
    }

    @media screen and (max-width: 480px) {
        font-size: 1rem;
    }

    &:hover {
        color: #ff5e71;
        transition: 0.3s ease-out;
        cursor: pointer;
    }
}

.Name {
    color: #1a2833;
}

.Col {
    padding: 0%;
    margin: 0%;
    margin-left: 0px;
}

.galleryimage {
    width: 100%;
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 10px;
    padding-bottom: 5px;
    border-radius: 35%;
    box-shadow: rgb(47, 47, 47);
}

.Col {
    padding: 0px !important;
}

.gallery {
    padding: 0px;
}

.gallerytitles {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 25px;
    color: rgb(63, 66, 116);
}

.gallerysubtitles {
    font-family: Arial, Helvetica, sans-serif;
    color: rgb(95, 95, 95);
    font-style: italic;
}

.linkedin {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 22px;
    color: rgb(42, 45, 102);

    &:hover {
        color: #ff5e71;
        transition: 0.3s ease-out;
    }
}

.Row {
    margin: 1px;
    display: flex;
    justify-content: center;
}
