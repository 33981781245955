html, body, #root, .App {
  background-color: #1a2833;
  color: #ffffff;
  font-family: 'Lato', sans-serif; 
}  

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none !important;
}

a:active {
  text-decoration: none;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #233543;
  border-radius: 10px;
}
 
::-webkit-scrollbar {
  width: 10px;
  background-color: #233543;
}
 
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, rgb(55, 71, 79)),
    color-stop(0.72, rgb(55, 71, 79)),
    color-stop(0.86, rgb(55, 71, 79))
  );
}