.Container {
padding-top: 0px;
}

.Sidebar {
    position: fixed;
    z-index: 999;
    width: 100%;
    height: auto;
    background: #1a2833;
    display: grid;
    align-items: center;
    right: 0;
    transition: top 0.3s;
    top: -630px;
}

.SidebarOpen {
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: #1a2833;
    display: grid;
    align-items: center;
    right: 0;
    transition: top 0.3s;
    opacity: 1;
    top: 0;
}

.CloseIcon path {
    color: #ffffff;
    fill: #ffffff;
    stroke: #ffffff;
}

.Icon {
    display: block;
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
}

.Wrapper {
    color: #fff;
}

.Menu {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 80px);
    text-align: center;
    padding-inline-start: 0px;

}

.Link {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    color: #fff;
    cursor: pointer;

    &:hover {
        color: #f83d50;
        transition: 0.2s ease-in-out;
    }
}

.BtnWrap {
    display: flex;
    justify-content: center;
}

.Button {
    text-align: center;
    border-radius: 50px;
    background: #ff5e71;
    white-space: nowrap;
    padding: 7px 20px;
    width: 140px;
    margin: 5px 5px;
    margin-left: 3px;
    color: #fff;
    font-size: 15px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #9ee5c4;
        color: #000;
    }
}
